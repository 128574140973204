import {useState, useEffect, useContext} from 'react';
import { 
    Box,
    Grid, 
    FormControl,
    InputLabel,
    Paper,
    makeStyles,
    Select,
    MenuItem,
    TextField,
    Link,
    Typography,
    Accordion, 
    AccordionSummary, 
    AccordionDetails,
    Button
} from '@material-ui/core';
import { ExpandMoreOutlined, SearchOutlined, ErrorOutlineOutlined } from "@material-ui/icons";
import { IResult } from '../types/IResult';
import { IHandling } from '../types/IHandling';
import { fetchHandlings, fetchPollutants, fetchStates } from '../api/api';
import { IPollutant } from '../types/IPollutant';
import { 
    ERRORS_API_POLLUTANTS, 
    ERRORS_API_HANDLINGS, 
    FILTER_STATE, 
    FILTER_STATE_OPTION_CHOOSE, 
    FILTER_RENOVATION, 
    FILTER_CREATION, 
    ERRORS_API_STATES,
    OVERALL_RULES,
    FIND_EXPERTS
} from '../strings';
import { Context, ResultConsumer } from '../contexts/ResultContext';
import { IState } from '../types/IState';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2),
        width: '100%'
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 140,
    },
    selectEmpty: {
        // marginTop: theme.spacing(2),
    },
}));
const Configuration = (): JSX.Element => {
    const classes = useStyles();

    const [error, setError] = useState<string>('');
    const [inputError, setInputError] = useState<string[]>([]);
    const [states, setStates] = useState<IState[]>([]);
    const [state, setState] = useState<IState | undefined>();
    const [construction, setConstruction] = useState<number>(1930);
    const [renovation, setRenovation] = useState<number>(1980);
    const resultContext = useContext(Context);

    /**
     * There may be multiple handlings per pollutant.
     * If there is a "national" handling AND a regional (state) handling
     * only the regional handling should be taken.
     * @param handlings 
     * @returns 
     */
    const filterHandlingByRegion = (
        handlings: IHandling[]
    ): IHandling[] => {
        const filteredHandlings = handlings.filter((
            handling
        ) => handling.state_id);

        return filteredHandlings.length 
            ? filteredHandlings
            : handlings;
    };

    /**
     * 
     * @param pollutants 
     * @param handlings 
     * @returns 
     */
    const mapPollutantsWithHandlings = (
        pollutants: IPollutant[],
        handlings: IHandling[]
    ): IResult[] => 
        pollutants.map((pollutant) => (
            {
                pollutant,
                handlings: filterHandlingByRegion(
                    handlings.filter((handling) => handling.pollutant_id === pollutant.id)
                )
            }
        ));

    /**
     * 
     * @param state_id 
     * @param construction 
     * @param renovation 
     * @returns 
     */
    const getResults = async(
            state_id: number,
            construction: number,
            renovation: number
        ): Promise<IResult[]> => {
            const pollutants = await fetchPollutants(
                state_id,
                construction,
                renovation,
            ).then(
                response => response
            ).catch(() => {
                setError(ERRORS_API_POLLUTANTS);
                return [];
            });
            const handlings = await fetchHandlings(
                state_id,
                construction,
                renovation,
            ).then(
                response => response
            ).catch(() => {
                setError(ERRORS_API_HANDLINGS);
                return [];
            });

            return mapPollutantsWithHandlings(
                pollutants,
                handlings
            )
        }

    const triggerRequest = () => {
        const err = [];
        if (!state) {
            err.push('Kein Bundesland ausgewählt');
        }
        if (!construction) {
            err.push('Kein Baujahr festgelegt');
        }
        if (renovation && construction >= renovation) {
            err.push('Sanierung kann nur nach Bau erfolgt sein');
        }
        if (err.length === 0 && state) {
            setInputError([]);
            getResults(
                state.id,
                construction as number,
                renovation as number
            ).then(response => resultContext.updateResults(response))
        } else {
            setInputError(err);
        }
    }

    useEffect(() => {
        (async() => {
            const states = await fetchStates()
                .then(
                    response => response
                ).catch(() => {
                    setError(ERRORS_API_STATES);
                    return [];
                });

            setStates(states);
        })();
    }, [setStates]);

    return(
        <ResultConsumer>
            {({ updateResults }) => (
                <>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <FormControl variant="filled" className={classes.formControl}>
                                    <InputLabel id="label-bundesland">
                                        { FILTER_STATE }
                                    </InputLabel>
                                    <Select
                                        labelId="label-bundesland"
                                        defaultValue=""
                                        onChange={(e) => {
                                            resultContext.updateResults([]);
                                            setState(
                                                states.find((state) => state.id === e.target.value as number)
                                            )
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>{ FILTER_STATE_OPTION_CHOOSE }</em>
                                        </MenuItem>
                                        {
                                            states && 
                                                states.map((state, i) => 
                                                    <MenuItem value={state.id} key={i}>
                                                        {state.name}
                                                    </MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        required
                                        id="filled-required"
                                        label={FILTER_CREATION}
                                        defaultValue={construction}
                                        variant="filled"
                                        type="number"
                                        onChange={(e) => {
                                            resultContext.updateResults([]);
                                            setConstruction(Number(e.target.value))
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        label={FILTER_RENOVATION}
                                        defaultValue={renovation}
                                        variant="filled"
                                        type="number"
                                        onChange={(e) => {
                                            resultContext.updateResults([]);
                                            setRenovation(Number(e.target.value))
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            {
                                error &&
                                    <Grid item xs={12}>
                                        <Typography color='secondary'>{error}</Typography>)
                                    </Grid>
                            }
                            {
                                inputError &&
                                    <Grid container spacing={1} direction="row" alignItems="flex-start" xs={12}>
                                        {
                                            inputError.map(err => 
                                                <>
                                                    <Grid item>
                                                        <ErrorOutlineOutlined color='secondary' />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography color='secondary'>
                                                            {err}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            )
                                        }
                                    </Grid>
                            }
                            <Grid item xs={12}>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    endIcon={<SearchOutlined />} 
                                    onClick={() => triggerRequest()}
                                >
                                    Jetzt suchen
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                    {
                        (state && state.links) &&
                            <Grid container spacing={2}>
                                <Grid item>
                                    <ol>
                                        {                                            
                                            state.links.map((link, i) => 
                                                <li key={i} >
                                                    <Link href={link.link} target="blank">
                                                        {link.description}
                                                    </Link>
                                                </li>
                                            )
                                        }
                                    </ol>
                                </Grid>
                            </Grid>
                    }
                    <Accordion>
                        <AccordionSummary 
                            expandIcon={<ExpandMoreOutlined />}
                        >
                            <Typography>
                                {OVERALL_RULES}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ol>
                                <li>
                                    <Link href="https://www.baua.de/DE/Angebote/Rechtstexte-und-Technische-Regeln/Regelwerk/TRGS/pdf/TRGS-524.pdf?__blob=publicationFile"
                                        target="blank"
                                    >
                                        TRGS 524: Schutzmaßnahmen bei Tätigkeiten in kontaminierten Bereichen
                                    </Link>
                                </li>
                                <li>
                                    <Link href="https://www.baua.de/DE/Angebote/Rechtstexte-und-Technische-Regeln/Regelwerk/TRGS/pdf/TRGS-900.pdf?__blob=publicationFile"
                                        target="blank">
                                        TRGS 900: Arbeitsplatzgrenzwerte
                                    </Link>
                                </li>
                                <li>
                                    <Link href="https://www.bgbau.de/fileadmin/Medien-Objekte/Medien/DGUV-Regeln/101_004.pdf"
                                        target="blank">
                                        DGUV Regel 101-004: Kontaminierte Bereiche
                                    </Link>
                                </li>
                                <li>
                                    <Link href="https://www.baua.de/DE/Themen/Arbeitsgestaltung-im-Betrieb/Gefahrstoffe/Arbeiten-mit-Gefahrstoffen/pdf/Gefahrstoffverordnung-Aenderungen.pdf?__blob=publicationFile&v=2"
                                        target="blank">
                                        Gefahrstoffverordnung
                                    </Link>
                                </li>
                            </ol>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary 
                            expandIcon={<ExpandMoreOutlined />}
                        >
                            <Typography>
                                {FIND_EXPERTS}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ol>
                                <li>
                                    <Link href="https://www.gesamtverband-schadstoff.de/33-1-sanierungsfachplaner--gutachter/"
                                        target="blank"
                                    >
                                        Gesamtverband Schadstoff - Sanierungsfachplaner
                                    </Link>
                                </li>
                                <li>
                                    <Link href="https://www.wlw.de/de/suche/sachverstaendige-fuer-innenraumschadstoffe"
                                        target="blank">
                                        WLW - Sachverständige für Innenraumschadstoffe
                                    </Link>
                                </li>
                            </ol>
                        </AccordionDetails>
                    </Accordion>
                    <Box m={2} />
                </>
            )}
        </ResultConsumer>
    );
}

export default Configuration;