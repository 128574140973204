export const FILTER_STATE = 'Bundesland';
export const FILTER_STATE_OPTION_CHOOSE = 'Bitte wählen';
export const FILTER_CREATION = 'Baujahr';
export const FILTER_RENOVATION = 'Jahr der Sanierung';
export const ERRORS_API_STATES = 'Bundesländer konnten nicht geladen werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.';
export const ERRORS_API_POLLUTANTS = 'Schadstoffe konnten nicht geladen werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.';
export const ERRORS_API_HANDLINGS = 'Behandlungen für Schadstoffe konnten nicht geladen werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.';

export const POLLUTANT_HANDLING_RENOVATION_HEADLINE = 'Sanierungsmaßnahmen';
export const POLLUTANT_HANDLING_DISPOSAL_HEADLINE = 'Entsorgung';
export const POLLUTANT_IDENTIFICATION = 'Identifikation';
export const POLLUTANT_DISEASES = 'Krankheitsbilder';

export const OVERALL_RULES = "Allgemeine Regelwerke";
export const FIND_EXPERTS = "Sachverständige finden";
export const FURTHER_INFORMATION = "Weiterführende Informationen";