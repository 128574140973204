import React, { Fragment, useState } from "react";
import { makeStyles, Typography, List, ListItem, Link, Card, CardContent, Grid } from '@material-ui/core';
import { ResultConsumer } from "../../contexts/ResultContext";
import { 
    POLLUTANT_IDENTIFICATION, 
    POLLUTANT_HANDLING_DISPOSAL_HEADLINE, 
    POLLUTANT_DISEASES, 
    POLLUTANT_HANDLING_RENOVATION_HEADLINE,
    FURTHER_INFORMATION
} from "../../strings";
import { IResult } from "../../types/IResult";
import { IHandling } from "../../types/IHandling";
import { LaunchOutlined, ChevronRightOutlined, KeyboardArrowUpOutlined } from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    card: {
        position: 'relative',
        height: '150px',
        '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            height: '100px',
            width: '100%',
            background: `linear-gradient(
                180deg, 
                transparent 0%, 
                ${theme.palette.background.paper} 60%
            )`,
            bottom: '0'
        },
        '&.visible': {
            height: 'auto'
        },
        '&.visible::after': {
            display: 'none'
        },
    }
}));

const Pollutant = () => {
    const classes = useStyles();
    const [visibleResults, setVisibleResults] = useState<number[]>([]);

    const generateHandlingImages = (
        imagePaths: IHandling['imagePaths']
    ) => 
        imagePaths.map((path, i) => {
            // Todo: clean up once description and alt tag are properly integrated
            if (path === 'radonMap.jpg') {
                return <>
                    <img 
                        alt={path} 
                        style={{maxHeight: "450px", maxWidth: "100%"}} 
                        key={i} 
                        src={`/uploads/${path}`} 
                        loading="lazy"
                    />
                    <Typography>
                        Quelle: Radon-Handbuch
                    </Typography>
                </>
            } else {
                return <img 
                    alt={path} 
                    style={{maxHeight: "450px", maxWidth: "100%"}} 
                    key={i} 
                    src={`/uploads/${path}`} 
                    loading="lazy"
                />
            }
        }
            
        )
    
    const generateHandlingLinks = (
        links: IHandling['links']
    ) => 
        <List>
        {
            links.map((link, i) => 
                <ListItem key={i}>
                    <Typography>
                        {
                            (link.link && link.link_title) &&
                            <>
                            <Link href={link.link} target="blank">
                                {link.link_title} <LaunchOutlined className="text-icon" />
                            </Link>
                            &nbsp;-&nbsp;
                            </>
                        }
                        {
                            link.description &&
                                <span className="text">{link.description}</span>
                        }
                    </Typography>
                </ListItem>
            )
        }
        </List>

    const generateHandlingInfo = (
        handlings: IHandling[]
    ) => 
        <>
        {
            handlings.map((handling, j) => 
                <Fragment key={j}>
                    {
                        handling.renovation &&
                            <>
                                <Typography variant="h3">
                                    {POLLUTANT_HANDLING_RENOVATION_HEADLINE}
                                </Typography>
                                <Typography>
                                    {handling.renovation}
                                </Typography>
                            </>
                    }
                    {
                        handling.disposal &&
                            <>
                                <Typography variant="h3">
                                    {POLLUTANT_HANDLING_DISPOSAL_HEADLINE}
                                </Typography>
                                <Typography>
                                    {handling.disposal}
                                </Typography>
                            </>
                    }
                    {
                        (handling.links && handling.links.length > 0) &&
                            <>
                                <Typography variant="h3">
                                    {FURTHER_INFORMATION}
                                </Typography>
                                {
                                    generateHandlingLinks(handling.links)
                                }
                            </>
                            
                    }
                    {
                        (handling.imagePaths && handling.imagePaths.length > 0) &&
                            generateHandlingImages(handling.imagePaths)
                    }
                </Fragment>
            )
        }
        </>

    const generatePollutantInfo = (
        result: IResult
    ) => 
        <div className={classes.paper}>
            <Typography>
                {result.pollutant.description}
            </Typography>
            {
                result.pollutant.identification &&
                    <>
                        <Typography variant="h3">
                            {POLLUTANT_IDENTIFICATION}
                        </Typography>
                        <Typography>
                            {result.pollutant.identification}
                        </Typography>
                    </>
            }
            {
                result.pollutant.diseases &&
                    <>
                        <Typography variant="h3">
                            {POLLUTANT_DISEASES}
                        </Typography>
                        <Typography className="pre-wrap">
                            {result.pollutant.diseases}
                        </Typography>
                    </>
            }
            {
                result.handlings.length > 0 &&
                    generateHandlingInfo(result.handlings)
            }
        </div>

    const generateResults = (
        results: IResult[]
    ) => 
        results
            .sort((prev, curr) => 
                prev.pollutant.name > curr.pollutant.name
                ? 1
                : -1
            )
            .map((result, i) => 
            <Grid 
                key={i} 
                item 
                md={
                    visibleResults.includes(result.pollutant.id)
                    ? 12
                    : 4
                } 
                xs={12}
            >
                <Card 
                    className={[
                        classes.card, 
                        visibleResults.includes(result.pollutant.id)
                        ? 'visible'
                        : ''
                    ].join(' ')}
                >
                    <CardContent>
                        <Typography>
                            {result.pollutant.name}
                        </Typography>
                        {
                            generatePollutantInfo(result)
                        }
                    </CardContent>
                    {
                    !visibleResults.includes(result.pollutant.id) &&
                        <div 
                            className='pollutant-more pointer'
                            onClick={
                                () => setVisibleResults(
                                    [...visibleResults, result.pollutant.id]
                                )
                            }
                        >
                            <Typography>
                                Mehr Informationen 
                            </Typography>
                            <ChevronRightOutlined />
                        </div>
                    }
                    {
                    visibleResults.includes(result.pollutant.id) &&
                        <div 
                            className='pollutant-more pointer'
                            onClick={
                                () => setVisibleResults(
                                    visibleResults.filter(id => result.pollutant.id !== id)
                                )
                            }
                        >
                            <Typography>
                                Informationen ausblenden 
                            </Typography>
                            <KeyboardArrowUpOutlined />
                        </div>
                    }
                </Card>
            </Grid>
    );

    return (
        <ResultConsumer>
            {({ results }) => 
                <Grid container spacing={2}>
                    {results && generateResults(results)}
                </Grid>
            }
        </ResultConsumer>
    );
}

export default Pollutant;