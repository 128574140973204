import axios from "axios";
import { IHandling } from "../types/IHandling";
import { IPollutant } from "../types/IPollutant";
import { IState } from "../types/IState";

const baseUrl = 'https://schadstoff-info.de/api';

const fetchHandlings = (
    state_id: number,
    construction: number,
    renovation: number,
) => 
    axios.get<IHandling[]>(`${baseUrl}/handlings`, {
        headers: {
            'Content-Type': 'application/json',
        },
        params: {
            state_id,
            renovation: renovation ? renovation : undefined,
            construction,
        },
        timeout: 10000,
    }).then((response) => {
        return response.data;
    }).catch((ex) => {
        throw(ex);
    });

    const fetchPollutants = (
        state_id: number,
        construction: number,
        renovation: number,
    ) => 
        axios.get<IPollutant[]>(`${baseUrl}/pollutants`, {
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                state_id,
                renovation: renovation ? renovation : undefined,
                construction,
            },
            timeout: 10000,
        }).then((response) => {
            return response.data;
        }).catch((ex) => {
            throw(ex);
        });

    const fetchStates = () => 
        axios.get<IState[]>(`${baseUrl}/states`, {
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: 10000,
        }).then((response) => {
            return response.data;
        }).catch((ex) => {
            throw(ex);
        });

export {fetchHandlings, fetchPollutants, fetchStates};