import './App.css';
import Configuration from './components/Configuration';
import { ThemeProvider } from "@material-ui/styles";
import {
  CssBaseline,
  createMuiTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Link,
  Typography,
  Box
} from "@material-ui/core";
import Pollutant from './components/results/Pollutant';
import { lime } from '@material-ui/core/colors';
import { ResultProvider } from './contexts/ResultContext';
import React from 'react';
import imgTimeBeam from './assets/timeBeam.png';
import imgDiagram from './assets/diagram.png';
import { InfoOutlined } from '@material-ui/icons';

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: lime,
    secondary: {
      main: "#fb718c"
    },
  },
  typography: {
    fontFamily: [
      'Open Sans'
    ].join(','),
    h1: {
      fontSize: "2rem",
      lineHeight: "2"
    },
    h2: {
      fontSize: "1.75rem",
      lineHeight: "2"
    },
    h3: {
      fontSize: "1.5rem",
      lineHeight: "2"
    },
    h4: {
      fontSize: "1.25rem",
      lineHeight: "2"
    },
  }
});

const App = () => {

  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Link className="pointer" onClick={handleClickOpen}>
        <Typography variant="h2">
          Schadstoff Info <InfoOutlined />
        </Typography>
      </Link>
      <ResultProvider>
        <CssBaseline />
        <Configuration />
        <Pollutant />
      </ResultProvider>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true}>
        <DialogTitle id="form-dialog-title">Schadstoff Info</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              Bereits der römische Architekt und Ingenieur Vitruv wusste um die gesundheitlichen Beeinträchtigungen, 
              die durch den Einsatz von schadstoffbelastetem Baumaterial, in diesem Falle Blei, 
              bei der Installation von Trinkwasserrohren entstehen, und so schrieb er im ersten Jahrhundert 
              vor Christus: <i>“Auch ist Wasser aus Tonröhren gesünder als das durch Bleiröhren geleitete, 
              denn das Blei scheint deshalb gesundheitsgefährlich zu sein, weil aus ihm Bleiweiß entsteht. 
              Dies aber soll dem menschlichen Körper schädlich sein.“</i>  Dennoch dauerte es in den alten Bundesländern 
              bis in das Jahr 1973, bis die Verwendung von Blei in Trinkwasserleitungen durch die Normenvorgabe verboten wurde. 
              In den neuen Bundesländern wurde Blei bei der Installation der Trinkwasserleitungen noch bis zur Wiedervereinigung eingesetzt.
            </Typography>
            <Box m={2} />
            <Typography>
              Seit Untersuchungen zu den schädlichen Auswirkungen einzelner Baustoffe bzw. in Baustoffen 
              enthaltenen Substanzen durchgeführt werden, ist das Thema der Beseitigung eben jener Stoffe zu einer alltäglichen Aufgabe 
              im Bauwesen geworden. Zahlreiche Verbote, Verordnungen und Regulierungen existieren 
              deutschland- und europaweit zu diesem Thema, und jedes Bundesland schreibt teilweise andere Vorgehensweisen 
              beim Umgang mit einzelnen Schadstoffen vor.
            </Typography>
            <Box m={2} />
            <Typography>
              Auf folgender Abbildung kann der Zeitraum nachvollzogen werden, in welchem einzelne Schadstoffe verwendet wurden.
            </Typography>
            <Box m={2} />
            <img className="image" loading="lazy" src={imgTimeBeam} alt="Zeitstrahl Schadstoffe" />
            <Box m={2} />
            <Typography>
              Auf der folgenden Seite kann mit den Parametern Bundesland, Bau- und Sanierungsjahr nachvollzogen werden, 
              welche häufig auftretenden Schadstoffe in einer Immobilie enthalten sein können. 
              Im nächsten Schritt wird dargestellt, wie nach jeweils landesabhängig geltendem Recht 
              mit den schadstoffbelasteten Baustoffen zu verfahren ist.
            </Typography>
            <Box m={2} />
            <img className="image" loading="lazy" src={imgDiagram} alt="Schaubild Bundesländer Schadstoffe" />
            <Box m={2} />
            <Typography variant="h2">
              Disclaimer
            </Typography>
            <Typography>
              Durch neue Untersuchungen zur Gesundheitsschädlichkeit einzelner Stoffe und den daraus gewonnen Erkenntnissen 
              werden Gesetze laufend entsprechend angepasst.
            </Typography>
            <Typography>
              Die Datenbank wird ständig gepflegt, aktualisiert und erweitert, dennoch werden Vollständigkeit 
              und Aktualität der gemachten Angaben nicht garantiert. 
            </Typography>
            <Typography>
              Im Bauwesen kommen noch weitere Schadstoffe vor, welche hier (noch) nicht aufgeführt werden. 
            </Typography>
            <Typography>
              <strong>
                Bitte beauftragen Sie in jedem Fall einen Sachverständigen vor Ort 
                und lassen die entsprechenden Untersuchungen durchführen.
              </strong>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ich habe den Disclaimer gelesen<br/>
            Weiter zum Formular
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default App;
