import React, { Component, createContext } from "react";
import { ICurrentResults } from "../types/ICurrentResults";
import { IResult } from "../types/IResult";

interface IContext extends ICurrentResults {
    updateResults: (results: IResult[]) => void,
}

export const Context = createContext<IContext>({
    updateResults: () => {}
});

export class ResultProvider extends Component {
    updateResults = (results: IResult[]) => {
        this.setState({ results });
    };

    state: IContext = {
        results: [],
        updateResults: this.updateResults,
    };

    render() {
        return (
            <Context.Provider value={this.state}>
                {this.props.children}
            </Context.Provider>
        );
    }
}

export const ResultConsumer = Context.Consumer;
